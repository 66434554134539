// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require('popper.js');
$ = require('jquery');
window.$ = window.jQuery = $;
require('bootstrap');
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

require('../../assets/stylesheets/stylesheets.js');

const images = require.context('../../assets/images', true)
const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", function() {
    $(document).ready(function (e) {
        document.getElementById("contact-form-submit").addEventListener("click", function (event) {
            event.preventDefault();
            document.getElementById('contact-form').submit()
        });

        document.getElementById("contact-form-submit-key").addEventListener("keydown", function (event) {
            event.preventDefault();
            if (event.code === "Enter") {
              document.getElementById('contact-form').submit();

              gtag('event','form', {
                    'event_category' : 'engagement',
                    'event_label' : 'click_button'
                });

                window.lintrk('track', { conversion_id: 4718196 });
            }

        });

        document.getElementById("1stfloor").addEventListener("click", function (event) {
            const firstFloor = document.getElementById('1stfloor_blueprint');
            const secondFloor = document.getElementById('2ndfloor_blueprint');
            const ffButton = document.getElementById('1stfloor');
            const sfButton = document.getElementById('2ndfloor');
            firstFloor.classList.remove('hidden-blueprint');
            secondFloor.classList.add('hidden-blueprint');
            ffButton.classList.add('selected');
            sfButton.classList.remove('selected');
        });


        document.getElementById("2ndfloor").addEventListener("click", function (event) {
            const firstFloor = document.getElementById('1stfloor_blueprint');
            const secondFloor = document.getElementById('2ndfloor_blueprint');
            const ffButton = document.getElementById('1stfloor');
            const sfButton = document.getElementById('2ndfloor');
            firstFloor.classList.add('hidden-blueprint');
            secondFloor.classList.remove('hidden-blueprint');
            ffButton.classList.remove('selected');
            sfButton.classList.add('selected');

        });

    })
})

$(document).ready(function() {
$('#my-link').click(function (event) {

    gtag('event', 'telefon', {
        'event_category' : 'engagement',
        'event_label' : 'click_button'
    });
});

});


